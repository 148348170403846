import { generateProductImageUrl, getSelectedProductIdFromActionInput } from '@msdyn365-commerce-modules/retail-actions';
import { ProductDimensionFull } from '@msdyn365-commerce/commerce-entities';
import MsDyn365, {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import {
    getByIdAsync,
    getDimensionValuesAsync,
    getVariantsByDimensionValuesAsync,
    searchByCriteriaAsync
} from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import {
    AttributeValue,
    CommerceProperty,
    ProductDimension,
    ProductDimensionValue,
    ProductSearchCriteria,
    SimpleProduct
} from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { variantQueryStringName } from '../Utilities/constants';
import getAttributesForSelectedVariant, { AttributesForSelectedVariantInput } from './get-attributes-for-selected-variant';
import { getQueryParamsFromQueryByName } from './utilities/utils';
// import { AttributesForSelectedVariantInput, getAttributesForSelectedVariantAction } from '@msdyn365-commerce-modules/product-specification/src/dataActions/get-attributes-for-selected-variant';

export interface IDefaultDimension {
    dimensionType: number;
    dimensionValue: string;
}

let shouldDefaultSet: boolean = true;
/**
 * Get selected variant action input class
 */
export class SelectedVariantInput implements IActionInput {
    public productId: number;
    public channelId: number;
    public matchingDimensionValues: ProductDimension[];

    constructor(productId: number, channelId: number, matchingDimensionValues?: ProductDimension[]) {
        this.productId = productId;
        this.channelId = channelId;
        this.matchingDimensionValues = matchingDimensionValues || [];
    }

    public getCacheKey = () => `SelectedVariant`;
    public getCacheObjectType = () => 'SimpleProduct';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * CreateInput method for the getSelectedVariant data action
 * @param inputData The input data passed to the createInput method
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): SelectedVariantInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);
    if (productId) {
        return new SelectedVariantInput(+productId, +inputData.requestContext.apiSettings.channelId, []);
    } else {
        throw new Error('Unable to create SelectedVariantInput, no productId found on module config or query');
    }
};

/**
 * Action method for the getSelectedVariant data aciton
 * @param input The action input class
 * @param ctx The action context
 */
// tslint:disable-next-line:cyclomatic-complexity max-func-body-length
async function getSelectedVariantAction(input: SelectedVariantInput, ctx: IActionContext): Promise<SimpleProduct | null> {
    //TODO: what is the use case for `matchingDimensionValues` to ever have values?
    // the `createInput` always passes an empty array to the input constructor. Where
    // else / how else is this getting called that would pass a populated array?
    const matchingDimensionValues = input.matchingDimensionValues ?? [];
    const variantId = ctx.requestContext.app.config.queryBasedVariantSelection
        ? getQueryParamsFromQueryByName(
            MsDyn365.isBrowser
                ? new URL(window.location.href.toString())
                : ctx.requestContext.url.requestUrl,
                variantQueryStringName)
        : undefined;
    let getByIdResponse;
    try {
        getByIdResponse = await getByIdAsync(
            { callerContext: ctx },
            matchingDimensionValues?.length === 0 && variantId && !Number.isNaN(Number(variantId))
                ? Number(variantId)
                : await getDefaultVariantId(input.productId, ctx) ?? input.productId,
            input.channelId);
    } catch (error) {
        console.log('[getByIdAsync Error calling API]', error);
    }
    // bellow cond will only be 1 if we have invalid variantid
    if (!getByIdResponse && variantId) {
        getByIdResponse = await getByIdAsync(
            { callerContext: ctx },
            input.productId,
            input.channelId);
    }

    // below condition will only be true if we have masterid in url and query
    if (getByIdResponse && !getByIdResponse?.MasterProductId) {
        getByIdResponse = await getByIdAsync(
            { callerContext: ctx },
            await getDefaultVariantId(getByIdResponse.RecordId, ctx) ?? input.productId,
            input.channelId);
    }

    const baseProduct: SimpleProduct = Array.isArray(getByIdResponse)
        ? getByIdResponse[0]
        : getByIdResponse;
    // Need to dereference this before editing it. Otherwise we might not
    // properly get the mobx events because if things aren't properly observable
    // they won't fire when you set them, and then if you don't deref the value in
    // the cache will match the value when you try to save it, so it won't detect any
    // changes there either
    let product: SimpleProduct | null = { ...baseProduct };
    const originalDimensionLength = product.Dimensions ? product.Dimensions.length : 0;

    if (product && product.RecordId > 0) {
        let baseProductHadUnmatchedDimension: boolean = false;

        product.Dimensions?.forEach(dimension => {
            const matchedTargetDimension = matchingDimensionValues.find(
                targetDimension => targetDimension.DimensionTypeValue === dimension.DimensionTypeValue
            );

            if (matchedTargetDimension) {
                dimension.DimensionValue = matchedTargetDimension?.DimensionValue;
            } else {
                baseProductHadUnmatchedDimension = true;
            }
        });

        const actionInput = new AttributesForSelectedVariantInput(
            product.RecordId,
            input.channelId,
            product
        );
        const productSpecificationData: AttributeValue[] = await getAttributesForSelectedVariant(actionInput, ctx)
            .catch((error: Error) => {
                error;
            }) || [];

        // check if default variant is given for product
        // const productSpecificationData: AttributeValue[] = await getAttributeValuesAsync({ callerContext: ctx, queryResultSettings: {} }, product.RecordId, input.channelId, 0);
        const defaultValues = productSpecificationData.find(data => data.Name?.toLowerCase().trim() === 'default variants')?.TextValue;
        const parsedDefaultData = defaultValues
            ? parseDefaultDimensionsData(defaultValues)
            : undefined;
        const productPreSelectionDimensions: ProductDimensionFull[] = [];

        //new - literally 10x faster!
        if (!product.MasterProductId) {
            // const now = (new Date()).valueOf()
            // console.time(`productPreSelectionDimensions${now}`);
            const asyncCalls = product.Dimensions?.map(async dimension => {
                const dimensionValues = await getDimensionValuesAsync(
                    { callerContext: ctx, queryResultSettings: {} },
                    product?.MasterProductId
                        ? product.MasterProductId
                        // @ts-ignore: nested in `if(product && product.RecordId > 0)`
                        : product.RecordId,
                    input.channelId,
                    dimension.DimensionTypeValue,
                    matchingDimensionValues.filter(value => value.DimensionTypeValue !== dimension.DimensionTypeValue),
                    null
                );

                // if dimensionValues have a length of exactly one then we need to make
                // it as selected dimension for getting variant of the product
                if (dimensionValues.length === 1 && shouldDefaultSet) {
                    dimension.DimensionValue = dimensionValues[0];
                } else {
                    // search through default dimensions to find current dimension
                    const defaultValueToFind = parsedDefaultData
                        ?.find(defaultData => defaultData.dimensionType === dimension.DimensionTypeValue)
                        ?.dimensionValue.toLowerCase()
                        .trim();

                    // look through all dimension values to find the default one
                    const defaultDimension: ProductDimensionValue | undefined = dimensionValues.find(
                        availableDimensionValue => availableDimensionValue.Value?.toLowerCase().trim() === defaultValueToFind || availableDimensionValue.DimensionId?.toLowerCase().trim() === defaultValueToFind
                    );

                    if (defaultDimension && shouldDefaultSet) {
                        dimension.DimensionValue = defaultDimension;
                    }
                }

                const fullDimension: ProductDimensionFull = {
                    ...dimension,
                    DimensionValues: dimensionValues
                };

                productPreSelectionDimensions.push(fullDimension);
            }) || [];

            await Promise.allSettled(asyncCalls);
        }
        //*/

        // if there is any selection in productPreSelectionDimensions array then get the variant
        if (productPreSelectionDimensions.length > 0) {
            const mappedDimensions = productPreSelectionDimensions
                .filter(dimension => dimension && dimension.DimensionValue)
                .map(dimension => {
                    return {
                        DimensionTypeValue: dimension.DimensionTypeValue,
                        DimensionValue: dimension.DimensionValue,
                        ExtensionProperties: dimension.ExtensionProperties
                    };
                });
            //console.log("mappedDimensions: ",mappedDimensions)
            if (originalDimensionLength > 0 && originalDimensionLength === mappedDimensions.length) {
                const variants = await getVariantsByDimensionValuesAsync(
                    { callerContext: ctx, queryResultSettings: {} },
                    baseProduct.RecordId,
                    input.channelId,
                    mappedDimensions
                );
                if (variants && variants.length === 1) {
                    //TODO: why is the `product` variable that has been built up through the rest of the code
                    // being overwriten in the final steps?
                    product = variants[0];
                }
            }
        }
        else if (productPreSelectionDimensions.length === 0 && !baseProductHadUnmatchedDimension && matchingDimensionValues.length > 0) {
            const variants = await getVariantsByDimensionValuesAsync(
                { callerContext: ctx, queryResultSettings: {} },
                baseProduct.RecordId,
                input.channelId,
                matchingDimensionValues
            );

            if (variants && variants.length > 0) {
                //TODO: why is the `product` variable that has been built up through the rest of the code
                // being overwriten in the final steps?
                product = variants[0];
            }
        }
        else {
            //TODO: what should happen here??
            //console.log("in 'else' for getVariantsByDimensionValuesAsync")
        }

        const newImageUrl = generateProductImageUrl(product, ctx.requestContext.apiSettings);

        if (newImageUrl) {
            product.PrimaryImageUrl = newImageUrl;
        }
    }

    shouldDefaultSet = false;

    return product;
}

const parseDefaultDimensionsData = (defaultValue: string): IDefaultDimension[] | undefined => {
    try {
        const defaultDimensions: IDefaultDimension[] = [];

        const splitDimensionTypes = defaultValue.split(',');
        splitDimensionTypes.forEach(dimensionData => {
            const [dimensionTypeString, dimensionValue] = dimensionData.split('_');
            const dimensionType = parseInt(dimensionTypeString, 10);
            defaultDimensions.push({
                dimensionType,
                dimensionValue
            });
        });

        return defaultDimensions;
    } catch (e) {
        console.log(e);
    }

    return undefined;
};

export const getDefaultVariantId = async (masterId: number, context: IActionContext): Promise<number | undefined> => {
    const productSearchCriteria: ProductSearchCriteria = {
        Ids: [masterId],
        Context: {
            ChannelId: +context.requestContext.apiSettings.channelId,
            CatalogId: +context.requestContext.apiSettings.catalogId
        },
        IncludeAttributes: true,
        SearchCondition: '*'
    };
    try {
        const productSearchResults = await searchByCriteriaAsync(
            { callerContext: context },
            productSearchCriteria
        );
        // If the ProductSearchResult API finds the products then populate the product image urls and return
        // otherwise if the API does not exist or does not return products proceed to the legacy flows for legacy/backward compatibility reasons
        context.telemetry.debug('Product search results returned', JSON.stringify(productSearchResults));
        if (productSearchResults.length > 0) {
            const defaultVariantId = productSearchResults[0]?.ExtensionProperties && productSearchResults[0]?.ExtensionProperties.find(
                (property: CommerceProperty) => property?.Key === "Unbxd_DefaultVariantID"
            )?.Value?.LongValue;
            return !defaultVariantId || defaultVariantId === 0 ? undefined : defaultVariantId;
        }
        return undefined;
    } catch (e) {
        // In case of an error fall back to legacy flow
        context.telemetry.error(`Error while getting productSearchResult: ${e}`);
        return undefined;
    }
};

/**
 * The complete getSelectedVariant data action
 */
export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-selected-variant',
    action: <IAction<SimpleProduct | null>>getSelectedVariantAction,
    input: createInput
});
