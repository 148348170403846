import ReactHtmlParser from 'react-html-parser';

const getHtmlParserValue = (value?: string) => {
    let parsedValue = '';
    const parsedArr = value && ReactHtmlParser(value);
    if (parsedArr && parsedArr?.length > 0) {
        parsedValue = parsedArr[0] ? parsedArr[0].toString() : '';
    }
    return parsedValue;
};

export default getHtmlParserValue;