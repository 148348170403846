/* eslint-disable no-duplicate-imports */
import { IPageSummaryData } from '@msdyn365-commerce-modules/page-summary';
import { buildCacheKey, generateImageUrl, getProductUrlSync, getSelectedProductIdFromActionInput, QueryResultSettingsProxy, removeDomainQspFromUrl } from '@msdyn365-commerce-modules/retail-actions';
import MsDyn365, {
    CacheType, createObservableDataAction, IAction, IActionContext, IActionInput,
    ICommerceApiSettings, ICreateActionContext, IRequestContext, getCatalogId
} from '@msdyn365-commerce/core';
import { AttributeValue, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { getAttributeValuesAsync, getByIdsAsync, getActivePricesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import getHtmlParserValue from '../../Utilities/get-html-parser-value';
import { IMfrmProductPageSummaryConfig } from './mfrm-product-page-summary.props.autogenerated';
import getSelectedVariant, { SelectedVariantInput } from '../../data-actions/get-selected-variant.override.action';
import { getQueryParamsFromQueryByName } from '../../data-actions/utilities/utils';
import { variantQueryStringName } from '../../Utilities/constants';
//import { getAllVariantsForProduct, BuyboxGetDimensionVariantAvailabilityInput } from '../../data-actions/buybox-get-all-variants-list';

/** Product Page Summary Input */
export class ProductPageSummaryInput implements IActionInput {
    public apiSettings: ICommerceApiSettings;
    public config: IMfrmProductPageSummaryConfig;
    public productId: number;
    public catalogId?: number;

    public constructor(config: IMfrmProductPageSummaryConfig, apiSettings: ICommerceApiSettings, productId: number, requestContext?: IRequestContext) {
        this.config = config || {};
        this.apiSettings = apiSettings;
        this.productId = productId;

        if (requestContext) {
            this.catalogId = getCatalogId(requestContext);
        }
    }

    public getCacheObjectType = (): string => 'ProductPageSummary';
    public getCacheKey = (): string => `${buildCacheKey('ProductPageSummary', this.apiSettings)}-${this.config.title}-${this.productId}-${this.catalogId ?? 0}`;
    public dataCacheType = (): CacheType => 'none';
}

//TODO: move to .data.ts
export interface IProductPageSummary extends IPageSummaryData {
    price?: number;
    variantId?: number;
    masterProductId?: number;
    productBrand?: string;
    priceValidUntil?: string;
    itemCondition?: string;
    isOutOfStockArray?: { key: string, value: string }[];
    gtinArray?: { key: string, value: string }[];
}

//NOTE: needed for `export default createObservableDataAction({})`
const createInput = (args: ICreateActionContext) => {
    const productId = getSelectedProductIdFromActionInput(args);

    if (productId) {
        return new ProductPageSummaryInput(<IMfrmProductPageSummaryConfig>args.config, args.requestContext.apiSettings, +productId, args.requestContext);
    } else {
        throw new Error('Unable to create ProductPageSummaryAction input, no productId found on module config or query');
    }

};

//TODO: move in a common utility file
function _getAttributeByName(attributeValues: AttributeValue[] | undefined, keyValue: string) {
    return attributeValues && attributeValues.find(currAttribute => currAttribute.Name && currAttribute.Name.toLowerCase().trim() === keyValue.toLowerCase().trim());
}

//TODO: move to utility file
function parseAttributeTextArray(text: string | string[] | undefined): { key: string, value: string }[] {
    const rtn: { key: string, value: string }[] = [];

    if (text !== undefined) {
        if (!(text instanceof Array)) {
            text = [text];
        }

        text.forEach(str => {
            str?.replace(/[\[\]"\s]/g, '').split(',').forEach(it => {
                const keyVal = it.split('|');
                rtn.push({
                    key: keyVal[0],
                    value: keyVal[1]
                });
            });
        });
    }

    return rtn;
}

const action = async (input: ProductPageSummaryInput, context: IActionContext): Promise<IProductPageSummary> => {
    const {
        config,
        productId,
        apiSettings,
        apiSettings: {
            channelId
        }
    } = input;
    const url = MsDyn365.isBrowser
        ? new URL(window.location.href.toString())
        : context.requestContext.url.requestUrl;
    //TODO: move get variantId from url logic into utility file and use in `createInput` methode
    let variantId = Number(getQueryParamsFromQueryByName(url, variantQueryStringName)) || undefined;
    if (!variantId) {
        try {
            const input = new SelectedVariantInput(productId, channelId);
            const selectedVariant = await getSelectedVariant(input, context) || undefined;
            variantId = selectedVariant?.RecordId;
            if (!variantId) {
                throw new Error(`Error - Had to fallback to MasterProductId for SEO Schema at ${url}`);
            }
        } catch (error) {
            console.error(error);
            variantId = productId;
        }
    }

    const byIdAsync = productId && getByIdsAsync(
        {
            callerContext: context,
            queryResultSettings: QueryResultSettingsProxy.getPagingFromInputDataOrDefaultValue(context)
        },
        apiSettings.channelId,
        [productId],
        null,
        input.catalogId ?? 0
    ).catch(e => {
        // Do nothing, if there's an error we fall back to values defined from config
        console.error("Error while calling getByIdAsync: ", e);
    });
    const attributeValuesAsync = getAttributeValuesAsync(
        {
            callerContext: context,
            queryResultSettings: QueryResultSettingsProxy.getPagingFromInputDataOrDefaultValue(context)
        },
        productId,
        apiSettings.channelId,
        apiSettings.catalogId
    ).catch(e => {
        // Do nothing, if error fall back to config values
        console.error("Error while calling getAttributeValuesAsync: ", e);
    });
    const activePricesAsync = getActivePricesAsync(
        { callerContext: context },
        {
            ChannelId: context.requestContext.apiSettings.channelId,
            CatalogId: context.requestContext.apiSettings.catalogId
        },
        [+variantId],
        new Date(),
        null,
        [],
        true
    ).catch(e => {
        // Do nothing, if error fall back to config values
        console.error("Error while calling getActivePricesAsync: ", e);
    });
    //wait for all async to resolve
    const asynValues = await Promise.allSettled([
        byIdAsync,
        attributeValuesAsync,
        activePricesAsync
    ]);

    //results from async calls
    const simpleProductsData = asynValues[0].status === "fulfilled" ? asynValues[0].value || undefined : undefined;
    const productAttributes = asynValues[1].status === "fulfilled" ? asynValues[1].value || undefined : undefined;
    const productActivePrices = asynValues[2].status === "fulfilled" ? asynValues[2].value || undefined : undefined;
    const productActivePrice = productActivePrices && productActivePrices[0];
    const simpleProductData: SimpleProduct | undefined = simpleProductsData && simpleProductsData[0];
    const itemCondition = 'NewCondition';

    const pageTitleAttribute = _getAttributeByName(productAttributes, 'pageTitle')?.TextValue;
    const pageTitle = pageTitleAttribute ? getHtmlParserValue(pageTitleAttribute) : '';

    const pageDescriptionAttribute = _getAttributeByName(productAttributes, 'pageDescription')?.TextValue;
    const pageDescription = pageDescriptionAttribute ? getHtmlParserValue(pageDescriptionAttribute) : '';

    const priceValidUntil = productActivePrice?.DiscountLines && productActivePrice.DiscountLines[0]?.ValidTo?.toISOString();

    const isOutOfStockArray = parseAttributeTextArray(
        ['EcomAllVariantOOSInfo1',
            'EcomAllVariantOOSInfo2',
            'EcomAllVariantOOSInfo3'
        ].map(attributeName => {
            return _getAttributeByName(productAttributes, attributeName)?.TextValue || '';
        })
    );

    const gtinArray = parseAttributeTextArray(
        ['EcomAllVariantWithGTIN1',
            'EcomAllVariantWithGTIN2',
            'EcomAllVariantWithGTIN3',
            'EcomAllVariantWithGTIN4',
            'EcomAllVariantWithGTIN5',
            'EcomAllVariantWithGTIN6',
            'EcomAllVariantWithGTIN7',
            'EcomAllVariantWithGTIN8',
            'EcomAllVariantWithGTIN9'
        ].map(attributeName => {
            return _getAttributeByName(productAttributes, attributeName)?.TextValue || '';
        })
    );

    if (simpleProductData) {
        let productUrl: string | undefined;
        let productBrand: string | undefined;

        try {
            productUrl = getProductUrlSync(simpleProductData, context);
            const canonicalDomain = context.requestContext.canonicalDomain;
            if (productUrl && canonicalDomain) {
                productUrl = `https://${canonicalDomain}${productUrl}`.toLocaleLowerCase();
                productUrl = removeDomainQspFromUrl(productUrl, context.requestContext);
            } else {
                productUrl = undefined;
            }
        } catch (e) {
            productUrl = undefined;
        }

        if (productAttributes) {
            productBrand = _getAttributeByName(productAttributes, 'Brand')?.TextValue; //getProductBrand(productAttributes);
        } else {
            productBrand = undefined;
        }

        return {
            title: pageTitle || simpleProductData.Name,
            description: pageDescription || simpleProductData.Description,
            sharingImageUrl: generateImageUrl(simpleProductData.PrimaryImageUrl, apiSettings),
            canonicalUrl: `${productUrl}?variantid=${variantId}`,
            faviconUrl: config && config.faviconUrl,
            variantId: variantId,
            masterProductId: simpleProductData.RecordId,
            price: productActivePrice?.CustomerContextualPrice,
            productBrand: productBrand,
            itemCondition: itemCondition,
            isOutOfStockArray: isOutOfStockArray,
            priceValidUntil: priceValidUntil,
            gtinArray: gtinArray
        };
        // If the action fails fallback to values defined from config
    } else if (config) {
        return {
            title: pageTitle || config.title,
            description: pageDescription || config.description,
            sharingImageUrl: config.sharingImage && config.sharingImage.src,
            faviconUrl: config && config.faviconUrl
        };
    } else {
        return {};
    }
};

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/page-summary/product-page-summary',
    action: <IAction<IProductPageSummary>>action,
    input: createInput
});